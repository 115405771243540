import React from 'react'
import'../assets/css/content.css'


export default function Content() {
    return (
        <div className='contents'>
            <div className='title'>
                <h4>Full Stack Web Developer</h4> <br/> 
                <p>Node.js | React | JavaScript | typeScript | Ruby on Rails</p>
            <hr />
            </div>
        </div>
    )
}
