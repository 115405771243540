import React from 'react'
import '../assets/css/footer.css'

export default function Footer() {
  return (
    <div className='footer'>
        <p>© 2024 Rafael Merlotto</p>
        {/* <a href="https://icons8.com/">icons8</a> */}
    </div>
  )
}
