import React from 'react'
import Header from '../components/Header'
import Info from '../components/Info'


export default function Portfolio() {


  return (
    <React.Fragment>
      <Header />
      <Info />
    </React.Fragment>
  )
}
